import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Navbar/Navbar";
import Topbar from "./Topbar/Topbar";
import Footer from "./Footer/Footer";
import DivWithColLg4 from "./components/DivWithColLg4/DivWithColLg4";
import DivWithColLg8 from "./components/DivWithColLg8/DivWithColLg8";
import { HashRouter, Route, Routes } from "react-router-dom";
import Register from "./components/Register/Register";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";

function App() {
  return (
    <div className="App">
      <Topbar />
      <Navbar />
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            {/* <DivWithColLg4 />
            <DivWithColLg8 /> */}
            <HashRouter>
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </HashRouter>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
