import React, { FC, useEffect, useState } from "react";
import styles from "./ForgotPassword.module.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveToken } from "../../store/sessionManager";
import { storeToken } from "../../util/userDataSlice";

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deviceInfo = {
    deviceType: 0,
    deviceId: "0.86589524944225354",
    notificationToken: "0.122987995395769112",
  };

  const forgotUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      email,
    };

    try {
      const response = await axios.post(
        "https://apis.nuhman.com/api/auth/password/resetlink",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        //dispatch(storeToken(response.data.accessToken));
        //showSuccessToast("Login successful!");
        //saveToken(response.data.accessToken);
        setSuccessMessage(response.data.data);
        setErrorMessage("");
      } else {
        setErrorMessage(response.data.data);
        setSuccessMessage("");
      }
    } catch (error: unknown) {
      // Narrow down the type of error to AxiosError
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // Check for a 500 Internal Server Error
          if (error.response.status === 500) {
            setErrorMessage(
              "Internal Server Error: " + error.response.data.data
            );
          } else {
            // Handle other types of error responses
            setErrorMessage(
              "Password reset link resent failed: " + error.response.data.data
            );
          }
        } else {
          // Handle Axios errors without a response
          setErrorMessage("Network error: " + error.message);
        }
      } else {
        // Handle non-Axios errors
        setErrorMessage("An unknown error occurred.");
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="col-lg-8">
        <div className="overlay position-relative bg-light rresult">
          <h3 className=" mg">{"Forgot Password"}</h3>
          <p>Please Enter your Email if you forgot your password</p>
          <form name="sentMessage" id="contactForm" onSubmit={forgotUser}>
            <div className="form-row mb-3">
              <div className="col-md-12">
                <input
                  type="email"
                  className="form-control p-4"
                  id="email"
                  name="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Your Email"
                  required
                />
              </div>
            </div>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            {successMessage && <p className="text-success">{successMessage}</p>}

            <div className="form-row">
              <div className="col-md-6">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </div>
            <div className="form-row mt-1 text-center">
              <div className="form-row mt-3 d-flex justify-content-center">
                <a href="/#/login" className="mx-2">
                  Login
                </a>{" "}
                Or
                <a href="/#/register" className="mx-2">
                  Register
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
