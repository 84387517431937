import React, { FC, useEffect, useState } from "react";
import styles from "./Home.module.css";
import DivWithColLg8 from "../DivWithColLg8/DivWithColLg8";
import DivWithColLg4 from "../DivWithColLg4/DivWithColLg4";
import { getToken, removeSessionToken } from "../../store/sessionManager";
import { useNavigate } from "react-router-dom";

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const [showForm, setShowForm] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (getToken() == null) {
      removeSessionToken();
      navigate("/login");
    }
  }, []);
  return (
    <>
      <DivWithColLg4 />
      <DivWithColLg8 />
    </>
  );
};

export default Home;
