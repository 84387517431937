import React, { FC, useState } from "react";
import "./Topbar.css";
import { Link } from "react-router-dom";

interface TopbarProps {}

const Topbar: FC<TopbarProps> = () => {
  const today = new Date();
  let text = today.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 2,
      },
    },
    nav: false,
    dots: true,
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center py-2 px-lg-5">
          <div className="col-lg-4">
            <a href="" className="navbar-brand d-none d-lg-block">
              <h1 className="m-0 display-5 text-uppercase sitetitle">
                <span className="text-primary sitetitle">NUHMAN</span>.com
              </h1>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
