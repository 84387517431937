import React, { FC } from "react";
import styles from "./DivWithColLg4.module.css";

interface DivWithColLg4Props {}

const DivWithColLg4: FC<DivWithColLg4Props> = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="overlay position-relative bg-light rresult">
          <p>**</p>
        </div>
      </div>
    </>
  );
};
export default DivWithColLg4;
