import React, { FC } from "react";
import styles from "./DivWithColLg8.module.css";

interface DivWithColLg8Props {}

const DivWithColLg8: FC<DivWithColLg8Props> = () => {
  return (
    <>
      <div className="col-lg-8">
        <div className="overlay position-relative bg-light rresult">
          <p>**</p>
        </div>
      </div>
    </>
  );
};

export default DivWithColLg8;
