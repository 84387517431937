import React, { FC, useEffect, useState } from "react";
import styles from "./Login.module.css";
import axios from "axios";
import { removeToken, storeToken } from "../../util/userDataSlice";
import { saveToken } from "../../store/sessionManager";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rMe, setrMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deviceInfo = {
    deviceType: 0,
    deviceId: "0.86589524944225354",
    notificationToken: "0.122987995395769112",
  };

  const loginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      email,
      password,
      deviceInfo,
    };

    try {
      const response = await axios.post(
        "https://apis.nuhman.com/api/auth/login",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        if (rMe) {
          localStorage.setItem("username", email);
          localStorage.setItem("password", password);
        }

        dispatch(storeToken(response.data.accessToken));
        //showSuccessToast("Login successful!");
        saveToken(response.data.accessToken);
        navigate("/home");
      } else {
        setErrorMessage(response.data.data);
        setSuccessMessage("");
      }
    } catch (error: unknown) {
      // Narrow down the type of error to AxiosError
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // Check for a 500 Internal Server Error
          if (error.response.status === 500) {
            setErrorMessage(
              "Internal Server Error: " + error.response.data.data
            );
          } else {
            // Handle other types of error responses
            setErrorMessage("Login failed: " + error.response.data.data);
          }
        } else {
          // Handle Axios errors without a response
          setErrorMessage("Network error: " + error.message);
        }
      } else {
        // Handle non-Axios errors
        setErrorMessage("An unknown error occurred.");
      }
    }
  };

  useEffect(() => {
    const userStore = localStorage.getItem("username");
    const passStore = localStorage.getItem("password");
    if (userStore) {
      setEmail(userStore);
    }
    if (passStore) {
      setPassword(passStore);
    }
  }, []);

  return (
    <>
      <div className="col-lg-8">
        <div className="overlay position-relative bg-light rresult">
          <h3 className=" mg">{"Login"}</h3>
          <p>Please Login with name and email</p>
          <form name="sentMessage" id="contactForm" onSubmit={loginUser}>
            <div className="form-row mb-3">
              <div className="col-md-12">
                <input
                  type="email"
                  className="form-control p-4"
                  id="email"
                  name="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Your Email"
                  required
                />
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="col-md-12">
                <input
                  type="password"
                  className="form-control p-4"
                  id="password"
                  name="password"
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Your Password"
                  required
                />
              </div>
            </div>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            {successMessage && <p className="text-success">{successMessage}</p>}

            <div className="form-row">
              <div className="col-md-6">
                <button type="submit" className="btn btn-primary btn-block">
                  Submit
                </button>
              </div>
            </div>
            <div className="div2">
              <p>
                <input
                  type="checkbox"
                  checked={rMe}
                  onChange={() => setrMe(!rMe)}
                />
                &nbsp; Remember Me
              </p>
            </div>
            <div className="form-row mt-1 text-center">
              <div className="form-row mt-3 d-flex justify-content-center">
                <a href="/#/forgot-password" className="mx-2">
                  Forgot Password?
                </a>
                <a href="/#/register" className="mx-2">
                  Register
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
